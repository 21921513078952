<template>
  <div class="setting_form clear">
    <div class="white_box  ">
      <div class="box_inner">
        <div class="box_head d-flex align-items-center">
          <div class="left">
            <h2>Miscellaneous</h2>

          </div>
          <!--<div class="right ml-auto">-->
          <!---->

          <!--</div>-->

        </div>
        <div class="box_content">
          <div class="notification_setting_wrapper">
            <div class="notification_setting">

              <div class="setting_list">

                <div class="setting_item">
                  <div class="text_sec">
                    <p>Append original link to the URL of the shortened {{ getSiteDetails.agency_name }} link.</p>
                  </div>
                  <div class="status_sec">
                    <label class="switch">
                      <input type="checkbox" v-model="getProfile.append_hash" @change="updateMiscSetting('append_hash')">
                      <div class="slider round"></div>
                    </label>
                  </div>
                </div>

              </div>
            </div>
            <div class="notification_setting" v-if="settingsAllowed()">

              <div class="setting_list">

                <div class="setting_item">
                  <div class="text_sec">
                    <p>Show "Powered by Replug" on your branded messages and Biolinks.</p>
                  </div>
                  <div class="status_sec">
                    <label class="switch" v-if="allowDisablingBranding()">
                      <input type="checkbox" v-model="getProfile.poweredBy" @change="updateMiscSetting('poweredBy')">
                      <div class="slider round"></div>
                    </label>
                    <label v-else v-tooltip.top-center="'This is only for Professional or higher plans. Please upgrade you package plan.'" class="switch">
                      <input @click.prevent="showUpgradeModal()" type="checkbox" v-model="!getProfile.poweredBy">
                      <div class="slider opacity-60 round"></div>
                    </label>
                  </div>
                </div>

              </div>
            </div>
            <div class="notification_setting">
              <div class="setting_list">
                <div class="setting_item">
                  <div class="text_sec">
                    <p>Transfer UTM Parameters to the Destination URL.</p>
                  </div>
                  <div class="status_sec">
                    <label class="switch">
                      <input type="checkbox" v-model="getProfile.transfer_utm"
                             @change="updateMiscSetting('transfer_utm')">
                      <div class="slider round"></div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="notification_setting">
              <div class="setting_list">
                <div class="setting_item">
                  <div class="text_sec">
                    <p>Show summary page as a fallback if destination URL has iframe issue.
                    </p>
                  </div>
                  <div class="status_sec">
                    <label class="switch">
                      <input type="checkbox" v-model="getProfile.fallback_cta"
                             @change="updateMiscSetting('fallback_cta')">
                      <div class="slider round"></div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ROLES } from '@/common/constants'

export default ({
  computed: {
    ...mapGetters(['getProfile', 'getSubscription', 'getWhiteLabel'])
  },
  methods: {
    ...mapActions([]),
    settingsAllowed () {
      return this.getProfile && [ROLES.super_admin, ROLES.admin].includes(this.getProfile.role) && !this.isWhiteLabelSetup()
    },
    allowDisablingBranding () {
      return this.getSubscription && this.getSubscription.limits.branding !== false
    }
  }

})
</script>
